import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null); // Conținut dinamic

	const openModal = (content) => {
		setModalContent(content); // Setează conținutul modalului
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setModalContent(null); // Resetează conținutul
	};

	return (
		<ModalContext.Provider value={{ isModalOpen, modalContent, openModal, closeModal }}>
			{children}
		</ModalContext.Provider>
	);
};