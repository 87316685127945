// import styles of this component
import { useContext, useEffect, useState } from "react";
import styles from "./MasonryBox.module.css"
import { PropTypes } from 'prop-types';
import { ModalContext } from "../../../ModalContext";

// MasonryBox component
const MasonryBox = ({ wallSrc, userProf, userName, userJob, ant }) => {

  const { openModal } = useContext(ModalContext);

  const ModalContent = ({ imageSrc }) => {
    const [isPortrait, setIsPortrait] = useState(false);

    useEffect(() => {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        if (img.height > img.width) {
          setIsPortrait(true);  // Dacă imaginea este în orientare portrait
        } else {
          setIsPortrait(false);  // Dacă imaginea este în orientare landscape
        }
      };
    }, [imageSrc]);

    return (
      <div className="modal-content">
        <img
          src={imageSrc}
          alt="Modal"
          className={isPortrait ? 'portrait' : ''}
        />
      </div>
    );
  };


  const [clickCount, setClickCount] = useState(0); // Contor pentru click-uri
  const [timeoutId, setTimeoutId] = useState(null); // Pentru a curăța timeout-ul

  // Resetarea contorului de click-uri dacă nu se face un click într-un interval scurt
  useEffect(() => {
    if (clickCount >= 3) {
      alert("Te iubesc!");
      setClickCount(0); // Resetăm contorul după ce s-a declanșat alerta
    } else if (clickCount > 0) {
      // Resetăm contorul după 2 secunde de inactivitate
      if (timeoutId) clearTimeout(timeoutId);
      const id = setTimeout(() => setClickCount(0), 500); // Resetare după 2 secunde
      setTimeoutId(id);
    }
  }, [clickCount]);

  const handleClick = (ant, wallSrc) => {
    if (typeof ant !== 'undefined')
      setClickCount((prev) => prev + 1); // Incrementăm contorul de click-uri
    else openModal(<ModalContent imageSrc={wallSrc} />)
  };

  return (
    <div className={styles["my-masonry"]} onClick={() => handleClick(ant, wallSrc)}>
      <img src={wallSrc} style={{ width: "100%" }} alt="" />
      {userProf && <div className={`${styles["my-masnry-description"]} flex`}>
        <div className={`${styles["my-masnry-user-box"]} flex align-items-center`}>
          <div className={styles["my-masnry-user-prof"]}>
            <img src={userProf} alt="" />
          </div>
          <div className={`${styles["my-masnry-user-prof-desc"]} flex flex-column`}>
            <h1>{userName}</h1>
            <h3>{userJob}</h3>
          </div>
        </div>
      </div>}
    </div>
  )
}

// validate MasonryBox component
MasonryBox.propTypes = {
  wallSrc: PropTypes.string.isRequired,
}

export default MasonryBox