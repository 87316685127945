import React, { forwardRef, useEffect, useState } from 'react';
import axios from 'axios';

const ContactForm = forwardRef(({ white = false, studio = false, userSelections = {}, price, state, modal }, ref) => {
	const [status, setStatus] = useState({
		submitted: false,
		submitting: false,
		info: { error: false, msg: null },
	});

	// Funcție pentru a transforma cheile în denumiri mai prietenoase
	const formatSelections = (selections) => {
		const labels = {
			selectedOption: "Tipul Evenimentului",
			select1Value: "Durata Evenimentului",
			select2Value: "Fotografii livrate",
			isCheckboxChecked: "Video",
			additionalSelectValue: "Durata Video",
			is4KChecked: "Rezolutie 4k",
			isDroneChecked: "Drona",
			isAlbumChecked: "Album Foto",
		};
		return Object.keys(selections).map((key) => {
			const label = labels[key] || key;
			const value = selections[key] ? (selections[key] === true ? 'Da' : selections[key]) : "Nu este selectat";

			if (!selections['isCheckboxChecked'] && (key === 'additionalSelectValue' || key === 'is4KChecked' || key === 'isDroneChecked')) return null
			return (
				<div key={key} className="selection-item">
					<strong>{label}:</strong> {value}
				</div>
			);
		});
	};
	const formatSelectionsText = (selections) => {
		const labels = {
			selectedOption: "Tipul Evenimentului",
			select1Value: "Durata Evenimentului",
			select2Value: "Fotografii livrate",
			isCheckboxChecked: "Video",
			additionalSelectValue: "Durata Video",
			is4KChecked: "Rezolutie 4k",
			isDroneChecked: "Drona",
			isAlbumChecked: "Album Foto",
		};

		let output = ''
		Object.keys(selections).map((key) => {
			const label = labels[key] || key;
			const value = selections[key] ? (selections[key] === true ? 'Da' : selections[key]) : "Nu este selectat";

			if (!selections['isCheckboxChecked'] && (key === 'additionalSelectValue' || key === 'is4KChecked' || key === 'isDroneChecked')) return null
			output += " | " + label + ": " + value + " "
		});
		return output
	};


	const [inputs, setInputs] = useState({
		nume: '',
		contact: '',
		message: '',
		pachet: formatSelectionsText(userSelections),
		pret: price,
		studio: ''
	});

	const handleServerResponse = (ok, msg) => {
		if (ok) {
			setStatus({
				submitted: true,
				submitting: false,
				info: { error: false, msg: msg },
			});
			setInputs({
				nume: '',
				contact: '',
				message: '',
				pachet: formatSelectionsText(userSelections),
				pret: price
			});
		} else {
			setStatus({
				info: { error: true, msg: msg },
			});
		}
	};

	const handleOnChange = (e) => {
		e.persist();
		setInputs((prev) => ({
			...prev,
			[e.target.id]: e.target.value,
		}));
		setStatus({
			submitted: false,
			submitting: false,
			info: { error: false, msg: null },
		});
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();
		setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
		let data = { ...inputs, pachet: formatSelectionsText(userSelections), pret: price };

		if (studio) {
			data = { ...inputs, studio: true }
		}
		axios({
			method: 'POST',
			url: 'https://formspree.io/f/xvggzgkv',
			data: data,
		})
			.then((response) => {
				handleServerResponse(
					true,
					'Multumesc, te voi contacta in curand pentru mai multe detalii.',
				);
			})
			.catch((error) => {
				console.log(error)
				handleServerResponse(false, error.response.data.error);
			});
	};



	return (
		<main className={`contactForm ${white ? 'white' : ''} ${state ? 'glow' : ''} ${modal ? 'modal' : ''}`} ref={ref}>
			<form onSubmit={handleOnSubmit}>
				<label htmlFor="nume">Nume</label>
				<input
					id="nume"
					type="text"
					name="nume"
					onChange={handleOnChange}
					required
					value={inputs.nume}
					className='custom-input'
				/>
				<label htmlFor="contact">Telefon/Email</label>
				<input
					id="contact"
					type="text"
					name="contact"
					onChange={handleOnChange}
					required
					value={inputs.contact}
					className='custom-input'
				/>

				{
					studio && <input
						id="studio"
						type="hidden"
						name="studio"
						onChange={handleOnChange}
						required
						value="studio"
						className='custom-input'
					/>
				}
				{Object.keys(userSelections).length !== 0 &&
					<><h4>Opțiuni selectate: <strong>{price}</strong> Lei</h4>
						<div className="user-selections-container">
							<div className="user-selections-wrapper">
								<div className="user-selections">
									{formatSelections(userSelections)}
								</div>
							</div>
							<input
								id="pachet"
								type="hidden"
								name="pachet"
								value={formatSelectionsText(userSelections)}
							/>
							<input
								id="pret"
								type="hidden"
								name="pret"
								value={price}
							/>
							<div className="arrow">&#8594;</div>
						</div>
					</>
				}
				<label htmlFor="message">Mesaj</label>
				<textarea
					id="message"
					name="message"
					onChange={handleOnChange}
					required
					value={inputs.message}
					className='custom-input'
				/>
				<button type="submit" disabled={status.submitting} className='custom-button'>
					{!status.submitting
						? !status.submitted
							? 'TRIMITE'
							: 'TRIMIS!'
						: 'Se trimite...'}
				</button>
			</form>
			<br />
			{status.info.error && (
				<div className="error">Error: {status.info.msg}</div>
			)}
			{!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
		</main>
	);
});

export default ContactForm;

