import React, { useContext } from 'react';
import { ModalContext } from '../../ModalContext';
import './Modal.css'; // Stiluri pentru modală

const Modal = () => {
	const { isModalOpen, modalContent, closeModal } = useContext(ModalContext);

	if (!isModalOpen) return null;

	return (
		<div className="modal-overlay" onClick={closeModal}>
			<div className="modal-content" onClick={(e) => e.stopPropagation()}>
				<button className="modal-close" onClick={closeModal}>
					&times;
				</button>
				{modalContent} {/* Afișează conținutul dinamic */}
			</div>
		</div>
	);
};

export default Modal;