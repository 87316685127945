import { useEffect, useState } from "react";

// import styles of this component
import styles from "./App.module.css"
import stylesNav from "../src/Components/Header/Header.module.css"

// import other components to use
import Header from './Components/Header/Header';
import Youtube from './Components/Youtube/Youtube';
import MasonryLayout from './Components/MasonryLayout/MasonryLayout';
import ContainerCard from './Components/ContainerCard/ContainerCard';
import Dropdown from './Components/Elements/Dropdown/Dropdown';
import Button from "./Components/Elements/Button/Button";

// import json files 
import images from "./Jsons/Images.json"
import Instagram from "./Components/Instagram/Instagram";
import MAP from "./Components/Map/Map";
import Carousel from "./Components/CAROUSEL/Carousel";
import ContactForm from "./Components/Contact/ContactForm";
import { Helmet } from "react-helmet";

// App component
const Studio = () => {
  // dropdown items
  const ddItems = [
    {
      id: 1,
      title: "Featured",
      active: true,
      folder: false
    },
    {
      id: 2,
      title: "Studio & Lifestyle",
      active: false,
      folder: 'lifestyle'
    },
    {
      id: 3,
      title: "Product",
      active: false,
      folder: 'product'
    },
    {
      id: 4,
      title: "Automotive",
      active: false,
      folder: 'automotive'
    },
  ]

  const [categoryImage, setCategoryImage] = useState(images.categories.featured)
  const [currentChannelId, setCurrentChannelId] = useState('UCt1jBjgjUpdQ4HB57R7BTHA');
  const [videos, setVideos] = useState([]);

  const baseUrl = 'https://wrapapi.com/use/horoscopulazi/alinzvincacom/youtube/0.0.1?wrapAPIKey=Wf16jTpl2Y1kNiGOfraoEQwbgc6mMM1d';

  useEffect(() => {
    (async () => {
      if (currentChannelId) {
        try {
          const data = await fetch(`${baseUrl}`).then(response => response.json());
          const regex = /href="([^"]+)"/;
          const str = data.data["first video"];
          const match = regex.exec(str);

          if (match) {
            setVideos(match[1]);
          } else {
            console.log('No match found');
          }
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, []);

  const takeDdTitle = (ddTitle) => {
    setCategoryImage(() => {
      const categoryChoose = ddTitle.folder

      // return categoryChoose ? imageList : [...images.categories['featured']]

      switch (ddTitle.id) {
        case 1: {
          return [...images.categories['featured']];
          break;
        }
        case 2: {
          const imagesFolder = require.context('../public/img/lifestyle/compressed/pictures', true);
          const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

          return imageList;
          break;
        }
        case 3: {
          const imagesFolder = require.context('../public/img/product/compressed/pictures', true);
          const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

          return imageList;
          break;
        }
        case 4: {
          const imagesFolder = require.context('../public/img/automotive/compressed/pictures', true);
          const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

          return imageList;
          break;
        }
        default: {
          return [...images.categories['featured']];
          break;
        }
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Alin Zvinca - Studio Experience</title>
        <meta name="description" content="Studio Foto Iasi Alin Zvinca. Fotografie fashion, produs, editorial, craciun, botez, sf valentin, 8 martie, paste" />
      </Helmet>
      <div className="containerBody studio">
        <Header studio />
        <h3 className={stylesNav["heading-header-second-title-christmas"]}>DECORUL PREZENT</h3>
        <Carousel studio />
        <div className={`${styles["appointment-area"]} appointment-studio-control`} >
          <h3 className={stylesNav["heading-header-second-title-christmas"]}>CONTACT</h3>
          <ContactForm white studio />
          <div className={styles['contact-area']} >
            <Button theme="" className={styles['contact-button']} target="_blank" onClick={() => window.location = 'https://wa.link/298mdb'} >Mai multe detalii? Hai pe Whatsapp!</Button>
          </div>
        </div>
        <Instagram />
        <div className="flex justify-content-center" style={{ marginTop: "50px", padding: '50px' }}>
          <ContainerCard>
            <div className={`${styles["gallery-setting"]} flex justify-content-between align-items-center`}>
              <div className={styles["gallery-titles"]}><h1 id="bangers">The shots that matter</h1></div>
              <div className={styles["break"]}></div>
              <div className={styles["tools"]}>
                <Dropdown title="Filter" items={ddItems} liftingDdTextUp={takeDdTitle} />
                <a href="mailto:zvanca.alin@yahoo.ro" className={styles["btn-mix-noborder"]} data-text="Need of something else?">Need of something else?</a>
              </div>
            </div>
            <MasonryLayout images={categoryImage} />
          </ContainerCard>
        </div>
      </div>
    </>
  )
}

export default Studio