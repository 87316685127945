import styles from "./Carousel.module.css"

const Carousel = ({ christmas = false, events = false, studio = false }) => {
  if (events) {
    return (<div className={styles["container"]}>
      <div className={styles["gallery-wrap"]}>
        <div className={`${styles["item"]} ${styles["events"]} ${styles["item-1"]}`}></div>
        <div className={`${styles["item"]} ${styles["events"]} ${styles["item-2"]}`}></div>
        <div className={`${styles["item"]} ${styles["events"]} ${styles["item-3"]}`}></div>
        <div className={`${styles["item"]} ${styles["events"]} ${styles["item-4"]}`}></div>
        <div className={`${styles["item"]} ${styles["events"]} ${styles["item-5"]}`}></div>
        <div className={`${styles["item"]} ${styles["events"]} ${styles["item-6"]}`}></div>
      </div>
    </div>)
  }
  if (studio) {
    return (<div className={styles["container"]}>
      <div className={styles["gallery-wrap"]}>
        <div className={`${styles["item"]} ${styles["studio"]} ${styles["item-1"]}`}></div>
        <div className={`${styles["item"]} ${styles["studio"]} ${styles["item-2"]}`}></div>
        <div className={`${styles["item"]} ${styles["studio"]} ${styles["item-3"]}`}></div>
        <div className={`${styles["item"]} ${styles["studio"]} ${styles["item-4"]}`}></div>
        <div className={`${styles["item"]} ${styles["studio"]} ${styles["item-5"]}`}></div>
        <div className={`${styles["item"]} ${styles["studio"]} ${styles["item-6"]}`}></div>
      </div>
    </div>)
  }
  if (christmas) {
    return (<div className={styles["container"]}>
      <div className={styles["gallery-wrap"]}>
        <div className={`${styles["item"]} ${styles["item-1"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-2"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-3"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-4"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-5"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-6"]}`}></div>
      </div>
    </div>)
  }
  return (
    <div className={styles["container"]}>
      <div className={styles["gallery-wrap"]}>
        <div className={`${styles["item"]} ${styles["item-1"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-2"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-3"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-4"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-5"]}`}></div>
        <div className={`${styles["item"]} ${styles["item-6"]}`}></div>
      </div>
    </div>
  )
}

export default Carousel