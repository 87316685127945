import { useEffect, useRef, useState } from "react";
import styles from "./Map.module.css"

const MAP = ({ christmas = false }) => {
	const elementRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (elementRef.current) {
				const rect = elementRef.current.getBoundingClientRect();
				const isVisible = (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <= (window.innerWidth || document.documentElement.clientWidth)
				);
				setIsVisible(isVisible);
			}
		};

		window.addEventListener('scroll', handleScroll);
		// Initial check on component mount
		handleScroll();

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={`${styles["map-layout"]} ${isVisible ? styles["onScreen"] : ''}`}
			ref={elementRef}>
			<div className={`${styles["map-controls"]} map-layout-control`}>
				<small>Alta locatie?</small>
				<a href="https://wa.link/298mdb" className={styles["btn-mix-noborder"]} data-text="Da-mi un mesaj!" target="_blank">Da-mi un mesaj!</a>
			</div>
		</div>
	)
}

export default MAP