import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import Christmas from './Christmas';
import AboutMePage from './AboutMe';
import Events from './Events';
import Studio from './Studio';
import { ModalProvider } from './ModalContext'; // Contextul pentru modală
import Modal from './Components/Modal/Modal'; // Modalul
import './index.css'

const Root = () => {
	return (
		<ModalProvider> {/* Învelește aplicația în contextul modalului */}
			<Router>
				<Routes>
					<Route path="/" element={<App />} />
					<Route path="/christmas" element={<Christmas />} />
					<Route path="/studio" element={<Studio />} />
					<Route path="/events" element={<Events />} />
					<Route path="/aboutme" element={<AboutMePage />} />
				</Routes>
			</Router>
			<Modal /> {/* Modalul va fi folosit pentru a afișa conținutul dinamic */}
		</ModalProvider>
	);
};

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);