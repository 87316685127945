import React, { useState, useEffect } from "react";

const CardComponent = ({
	userSelections = {
		selectedOption: "nunta", // Opțiunea implicită
		select1Value: "Mai putin de 3 ore", // Opțiunea implicită pentru select1
		select2Value: "100 - 200", // Opțiunea implicită pentru select2
		isCheckboxChecked: false, // Checkbox implicit
		additionalSelectValue: "", // Opțiunea pentru selectul adițional implicit
		is4KChecked: false, // Checkbox 4K implicit
		isDroneChecked: false, // Checkbox Drone implicit
		isAlbumChecked: false, // Checkbox Album implicit
	},
	onSelectionsUpdate,
	updatePrice,
	onAction
}) => {
	// Structura de date pentru preturi
	const prices = {
		savethedate: {
			basePrice: 500,
			select1: [
				{ label: "Sedinta Foto", price: 0 },
			],
			select2: [
				{ label: "5 - 10", price: 0 },
				{ label: "10 - 50", price: 400 },
				{ label: "50+", price: 750 },
			],
			additionalSelect: [
				{ label: "3 - 15 minute", price: 400 },
				{ label: "15 - 30 minute", price: 800 },
			],
		},
		cununie: {
			basePrice: 700,
			select1: [
				{ label: "Mai putin de o ora", price: 0 },
				{ label: "Cununie + Sedinta Foto", price: 300 },
			],
			select2: [
				{ label: "30 - 70", price: 0 },
				{ label: "70 - 100", price: 200 },
				{ label: "100+", price: 500 },
			],
			additionalSelect: [
				{ label: "3 - 15 minute", price: 400 },
				{ label: "15 - 30 minute", price: 800 },
			],
		},
		nunta: {
			basePrice: 2000,
			select1: [
				{ label: "Mai putin de 3 ore", price: 0 },
				{ label: "Intre 3 si 7 ore", price: 1500 },
				{ label: "Intre 7 si 12 ore", price: 2500 },
			],
			select2: [
				{ label: "100 - 200", price: 0 },
				{ label: "200 - 300", price: 500 },
				{ label: "300+", price: 700 },
			],
			additionalSelect: [
				{ label: "5 - 30 minute", price: 700 },
				{ label: "30 - 60 minute", price: 1500 },
				{ label: "1+ ore", price: 2000 },
			],
		},
		botez: {
			basePrice: 1000,
			select1: [
				{ label: "Mai putin de 2 ore", price: 0 },
				{ label: "Intre 2 si 5 ore", price: 700 },
				{ label: "Intre 5 si 10 ore", price: 2000 },
			],
			select2: [
				{ label: "50 - 100", price: 0 },
				{ label: "100 - 200", price: 250 },
				{ label: "200+", price: 500 },
			],
			additionalSelect: [
				{ label: "5 - 30 minute", price: 300 },
				{ label: "30 - 60 minute", price: 500 },
				{ label: "1+ ore", price: 1000 },
			],
		},
		majorat: {
			basePrice: 1000,
			select1: [
				{ label: "Mai putin de 2 ore", price: 0 },
				{ label: "Intre 2 si 5 ore", price: 500 },
				{ label: "Intre 5 si 7 ore", price: 1000 },
			],
			select2: [
				{ label: "50 - 100", price: 0 },
				{ label: "100 - 200", price: 250 },
				{ label: "200+", price: 500 },
			],
			additionalSelect: [
				{ label: "5 - 30 minute", price: 300 },
				{ label: "30 - 60 minute", price: 700 },
				{ label: "1+ ore", price: 1000 },
			],
		},
		corporate: {
			basePrice: 1500,
			select1: [
				{ label: "Mai putin de 2 ore", price: 0 },
				{ label: "Intre 2 si 5 ore", price: 500 },
				{ label: "Intre 5 si 7 ore", price: 1000 },
			],
			select2: [
				{ label: "30 - 50", price: 0 },
				{ label: "50 - 100", price: 250 },
				{ label: "100+", price: 500 },
			],
			additionalSelect: [
				{ label: "1 - 15 minute", price: 500 },
				{ label: "15 - 30 minute", price: 800 },
				{ label: "30 - 60 minute", price: 1200 },
			],
		},
	};

	// Calcularea prețului total
	const calculateTotalPrice = () => {

		const {
			selectedOption,
			select1Value,
			select2Value,
			isCheckboxChecked,
			additionalSelectValue,
			is4KChecked,
			isDroneChecked,
			isAlbumChecked,
		} = userSelections;

		const selectedPriceData = prices[selectedOption];

		if (!selectedPriceData) {
			console.error("Opțiunea selectată nu este validă:", selectedOption);
			return 0;
		}

		const basePrice = selectedPriceData.basePrice;
		const additionalPrice1 = selectedPriceData.select1.find(
			(item) => item.label === select1Value
		)?.price || 0;
		const additionalPrice2 = selectedPriceData.select2.find(
			(item) => item.label === select2Value
		)?.price || 0;
		const additionalPrice3 =
			isCheckboxChecked &&
			selectedPriceData.additionalSelect.find(
				(item) => item.label === additionalSelectValue
			)?.price || 0;

		const extra4K = is4KChecked ? 500 : 0;
		const extraDrone = isDroneChecked ? 500 : 0;
		const extraAlbum = isAlbumChecked ? 700 : 0;

		const price = basePrice +
			additionalPrice1 +
			additionalPrice2 +
			additionalPrice3 +
			extra4K +
			extraDrone +
			extraAlbum

		updatePrice(price)

		return (
			basePrice +
			additionalPrice1 +
			additionalPrice2 +
			additionalPrice3 +
			extra4K +
			extraDrone +
			extraAlbum
		);
	};

	const totalPrice = calculateTotalPrice();

	const handleSelectChange = (event) => {
		const selectedOption = event.target.value;
		if (prices[selectedOption]) {
			onSelectionsUpdate({
				...userSelections,
				selectedOption,
				select1Value: prices[selectedOption].select1[0].label,
				select2Value: prices[selectedOption].select2[0].label,
				isCheckboxChecked: false,
				additionalSelectValue: "",
				is4KChecked: false,
				isDroneChecked: false,
				isAlbumChecked: false,
			});
		} else {
			console.error("Opțiune invalidă selectată:", selectedOption);
		}
	};

	const handleCheckboxChange = () => {
		onSelectionsUpdate({
			...userSelections,
			isCheckboxChecked: !userSelections.isCheckboxChecked,
			additionalSelectValue: !userSelections.isCheckboxChecked
				? prices[userSelections.selectedOption].additionalSelect[0].label
				: "",
		});
	};

	useEffect(() => {
		onSelectionsUpdate({
			...userSelections,
			select1Value: prices[userSelections.selectedOption].select1[0].label,
			select2Value: prices[userSelections.selectedOption].select2[0].label,
		});
	}, []);

	return (
		<div className="card-container">
			<div className="card">
				<select
					className="custom-select"
					value={userSelections.selectedOption}
					onChange={handleSelectChange}
				>
					<option value="savethedate">Save the Date</option>
					<option value="cununie">Cununie</option>
					<option value="nunta" selected>Nuntă</option>
					<option value="botez">Botez</option>
					<option value="majorat">Majorat</option>
					<option value="corporate">Corporate</option>
				</select>

				<div className="card-content">
					<div className="form-group">
						<label>Durata evenimentului:</label>
						<select
							className="custom-select"
							value={userSelections.select1Value}
							onChange={(e) => onSelectionsUpdate({ ...userSelections, select1Value: e.target.value })}
						>
							{prices[userSelections.selectedOption]?.select1.map((item) => (
								<option key={item.label} value={item.label}>
									{item.label} {item.price !== 0 && `(+${item.price} lei)`}
								</option>
							))}
						</select>
					</div>

					<div className="form-group">
						<label>Fotografii livrate:</label>
						<select
							className="custom-select"
							value={userSelections.select2Value}
							onChange={(e) => onSelectionsUpdate({ ...userSelections, select2Value: e.target.value })}
						>
							{prices[userSelections.selectedOption]?.select2.map((item) => (
								<option key={item.label} value={item.label}>
									{item.label} {item.price !== 0 && `(+${item.price} lei)`}
								</option>
							))}
						</select>
					</div>

					<div className="form-group checkbox-container">
						<label>
							<input
								type="checkbox"
								className="custom-checkbox"
								checked={userSelections.isCheckboxChecked}
								onChange={handleCheckboxChange}
							/>
							<strong>Video?</strong>
						</label>
					</div>

					{userSelections.isCheckboxChecked && (
						<>
							<div className="form-group">
								<label>Durata Montaj Video:</label>
								<select
									className="custom-select"
									value={userSelections.additionalSelectValue}
									onChange={(e) => onSelectionsUpdate({ ...userSelections, additionalSelectValue: e.target.value })}
								>
									{prices[userSelections.selectedOption]?.additionalSelect.map((item) => (
										<option key={item.label} value={item.label}>
											{item.label} (+{item.price} lei)
										</option>
									))}
								</select>
							</div>
							<div className="form-group checkbox-container">
								<label>
									<input
										type="checkbox"
										className="custom-checkbox"
										checked={userSelections.is4KChecked}
										onChange={() => onSelectionsUpdate({ ...userSelections, is4KChecked: !userSelections.is4KChecked })}
									/>
									<strong>Rezoluție 4K? (+500 lei)</strong>
								</label>
							</div>
							<div className="form-group checkbox-container">
								<label>
									<input
										type="checkbox"
										className="custom-checkbox"
										checked={userSelections.isDroneChecked}
										onChange={() => onSelectionsUpdate({ ...userSelections, isDroneChecked: !userSelections.isDroneChecked })}
									/>
									<strong>Dronă? (+500 lei)</strong>
								</label>
							</div>
						</>
					)}

					<div className="form-group checkbox-container">
						<label>
							<input
								type="checkbox"
								className="custom-checkbox"
								checked={userSelections.isAlbumChecked}
								onChange={() => onSelectionsUpdate({ ...userSelections, isAlbumChecked: !userSelections.isAlbumChecked })}
							/>
							<strong>Album foto printat? (+700 lei)</strong>
						</label>
					</div>
				</div>

				<button className="custom-button" onClick={onAction}>
					Preț estimativ: {totalPrice} lei &#x2192;
				</button>
			</div>
		</div>
	);
};

export default CardComponent;