import styles from "./Nav.module.css"
import Button from "../Elements/Button/Button"
import { HambergerMenu } from "iconsax-react"
import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { ModalContext } from '../../ModalContext';
import ContactForm from "../Contact/ContactForm"

const Nav = ({ setParentMenuOpen, white = false }) => {
    const { openModal } = useContext(ModalContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleMouseEnter = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <>
            {menuOpen && <div className={`flex ${styles["mobile-navbar"]} mobile-navbar-control`} onClick={() => { setMenuOpen(!menuOpen); setParentMenuOpen(!menuOpen) }}>
                <ul className={`flex align-items-center`}>
                    <li className={`nav-item ${styles.active}`}>
                        <a href="/" className={"nav-link"}>Try My Experience</a>
                    </li>
                    <li className={`nav-item ${styles.active}`}>
                        <a href="/studio" className={"nav-link"}>Try <strong>STUDIO</strong> Experience</a>
                    </li>
                    <li className={`nav-item ${styles.active}`}>
                        <a href="/events" className={"nav-link"}>Try Events Experience</a>
                    </li>
                    <li className={`nav-item ${styles.active}`}>
                        <a href="/christmas" className={"nav-link"}>Try Christmas Experience</a>
                    </li>
                    <li className={`nav-item ${styles.active}`}>
                        <a href="https://shootme.alinzvinca.com" className={"nav-link"}>Try Automotive Experience</a>
                    </li>
                    <li className={`nav-item ${styles.active}`}>
                        <a href="https://alinzvinca.gumroad.com" className={"nav-link"}>Presets</a>
                    </li>
                    <li className={`nav-item ${styles.active}`}>
                        <Link to="/aboutme" className={"nav-link"}>About me</Link>
                    </li>
                </ul>
            </div>}
            <nav className={`${styles.nav} flex align-items-center`}>
                <Link to="/" ><h1 className={`${styles["nav-title"]} ${styles["nav-logo"]}`}><img src={`${white === true ? './img/logoBlack.png' : './img/logo.png'}`} /></h1></Link>
                <ul className={`flex align-items-center ${styles["navbar-nav"]}`}>
                    <li className={`nav-item`}
                        style={{ position: 'relative' }}
                        onClick={handleMouseEnter}>
                        <Button theme="" className={`${styles["nav-link"]} ${styles.dropdownButton}`}>Try other Experiences</Button>
                        {/* Wrap the button and the dropdown */}
                        <div className={`${styles.dropdownContainer}`}>
                            {dropdownOpen && (
                                <div className={`${styles.dropdownMenu}`}>
                                    <Link to="/" className={`${styles.dropdownItem}`}>My Experience</Link>
                                    <Link to="/studio" className={`${styles.dropdownItem}`}><strong>STUDIO</strong> Experience</Link>
                                    <Link to="/events" className={`${styles.dropdownItem}`}>Events Experience</Link>
                                    <Link to="/christmas" className={`${styles.dropdownItem}`}>Christmas Experience</Link>
                                    <a href="https://shootme.alinzvinca.com" className={`${styles.dropdownItem}`}>Automotive Experience</a>
                                </div>
                            )}
                        </div>
                    </li>
                    <li className={`nav-item`}>
                        <a href="https://alinzvinca.gumroad.com" className={"nav-link"}>Presets</a>
                    </li>
                    <li className={`nav-item`}>
                        <Link to="/aboutme" className={"nav-link"}>About me</Link>
                    </li>
                </ul>
                <div className={`flex ${styles["navbar-buttons"]}`}>
                    <Button theme="" onClick={() => openModal(<><ContactForm modal /><div className={styles['contact-area']} >
                        <Button theme="" className={styles['contact-button']} target="_blank" onClick={() => window.location = 'https://wa.link/298mdb'} >Mai multe detalii? Hai pe Whatsapp!</Button>
                    </div></>)} className={styles['contact-button']}>Contact</Button>
                    <Button theme="" onClick={() => window.location = 'https://maps.app.goo.gl/qfzeqDxBHvH9dwDT8'} className={styles['contact-button']}>Locatie</Button>
                </div>
                <div className={styles["navbar-responsive-menu"]}>
                    <Button theme="transparent">
                        <HambergerMenu size="32" color="var(--white-100)" onClick={() => { setMenuOpen(!menuOpen); setParentMenuOpen(!menuOpen) }} />
                    </Button>
                </div>
            </nav>
        </>
    )
}

export default Nav;